.signup-form form{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    /* background: linear-gradient(105.93deg, #9B28A8 10.92%, #9208F3 88.71%); */
    padding: 40px;
    border-radius: 10px;
    z-index: 99;
    border-top: 1px solid #9208F3;
    border-left: 1px solid #9208F3;
    border-bottom: 1px solid #220139;
    border-right: 1px solid #220139;
    background: #05000DCC;
    width: 40%;
}

.emailInput{
    width: 100%;
}

.signup-form form h2{
    text-align: center !important;
}

.emailInput input,
.emailInput input:active,
.emailInput input:focus{
    border-radius: 3px;
    border: 1px solid #9B28A8;
    background:none;
    width: 100%;
    outline: none !important;
    padding-left: 10px;
    height: 50px;
    color: #ffff;
}

.pswd-div {
    position: relative;
}

.pswd-div svg{
    position: absolute;
    right: 15px;
    top: 17px;
}

.pswd-div svg path{
   color:#9B28A8;
}



.emailInput label{
    color: #ffff;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.emailInput span{
    color: #9B28A8;
    text-decoration: underline;
    cursor: pointer;
}

.emailInput p{
    color: #ffff;
    font-size: 14px;

}

.signInn{
    margin-top: 10px;
    height: 50px;
    border-radius: 5px;
    border: none;
    background: linear-gradient(105.93deg, #6b0876 10.92%, #8f037a 88.71%);
    color: #ffff;
    width: 100%;
}

.googleBtn button{
    border: none;
}
.googleBtn{
    /* margin: auto; */
    width: 100%;
    color: #000000;
}

.forget-pass{
    color: #6b0876;
    text-decoration: underline;
    font-size: 14px;
}

a{
    color: #6b0876;
}

.flex-div{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.profile-pic{
    border-radius: 100%;
}

.cursor-pointer{
    cursor: pointer;
}


/* ----- Media Queries ----- */
@media screen and (max-width: 992px){
    .signup-form form{
        margin-top: 2rem;
        gap: 5px;
        /* background: linear-gradient(105.93deg, #9B28A8 10.92%, #9208F3 88.71%); */
        padding: 0.5rem 2rem;
        width: 100%;
    }
    .signInn{
        margin-top: 5px;
        height: 45px;
        width: 100%;
    }
    .emailInput label{
        font-size: 12px;
    }
}

@media screen and (max-width: 576px){
    .signup-form form{
        margin-left: auto;
        margin-right: auto;
        gap: 5px;
        /* background: linear-gradient(105.93deg, #9B28A8 10.92%, #9208F3 88.71%); */
        padding: 0.5rem 0.5rem;
        width: 100%;
    }
    .emailInput input,
    .emailInput input:active,
    .emailInput input:focus{
        width: 100%;
        height: 2rem;
        border-radius: 3px;
    }
    .pswd-div svg{
        top: 9px;
    }
}