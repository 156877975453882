*{
    font-family: "Arimo", serif;
}

body {
    background-image: url("../assetsNew/image/background.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top;
    background-attachment: scroll;
    height: 100%;
    background-color: #000000;
}


.header-btn{
    display: block;
}

.linear-btn {
    align-items: center;
    background-image: linear-gradient(180deg, rgba(148, 0, 253, 1) 42%, rgb(14 0 38 / 87%) 100%);
    border: 0;
    border-radius: 30px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-size: 14px;
    justify-content: center;
    padding: 1px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    z-index: 999;
    height: min-content;
}

.linear-btn span {
    background-color: #0E0026;
    padding: 6px 12px;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    transition: 300ms;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.linear-btn img{
    margin-left: 5px;
    color: #E9DEFF;
}

.main-text{
    color: #E9DEFF;
    font-size: 40px;
    font-weight: 500;
    line-height: normal;
}

.header-vector img{
    position: absolute;
    top: 0;
    left: 250px;
    opacity: 0.5;
    z-index: -1 !important;
}

.main-card{
    backdrop-filter: blur(20px);
    background-color: #0000006a;
    border-radius: 21px;
    border: 1px solid #9b28a850;
}

.left-card{
    background-image: url("../assetsNew/image/IndigoDrift.png");
    background-color: #000000;
    background-size: 50% 100%;
    background-repeat: no-repeat; 
    background-position: right;
    color: #E6E6E6;
    padding: 10px 15px;
    border-radius: 10px;
    margin: 5px;
    width: 400px;
    height: 200px;
}

.left-card p{
    width: 75%;
    font-size: 14px;
}

.left-card h3{
    font-size: 22px;
}

.arrow-line{
    margin-left: -3rem;
    margin-right: -3rem;
}

.arrow-line img{
    width: 93%;
}

.uparrow{
    margin-bottom: -5px;
}

.middle-card{
    color: #E9DEFF;
    background-color: #2f2e2e68;
    border-radius: 12.7px;
    height: 200px;
    width: 350px;
    border-top: 1px solid #e2e2e2d6;
    border-left: 1px solid #e2e2e29c;
    border-right: 1px solid #e2e2e29c;
}

.upper-text p{
    font-size: 10px;
    margin-bottom: 0;
}

.upper-text h3{
    font-size: 17px;
    width: 70%;
    margin-top: -10px;
}

.line-img{
    margin-top: -2rem;
}
.line-img2{
    margin-top: -3rem;
}

.card-image{
    margin-right: -11px;
}

.lower-text{
    margin-top: -1rem;
}

.lower-text2{
    margin-top: -1.5rem;
}

.lower-text p:first-child{
    font-size: 14px;
    margin-bottom: 6px;
}
.lower-text2 p:first-child{
    font-size: 14px;
    margin-bottom: 6px;
}

.lower-text p, .lower-text2 p{
    font-size: 12px;
}

/* === input style === */
.search-input{
    position: relative;
}

.search-input input {
    background: #2E2E2ECC;
    height: 45px;
    color: #E9DEFF;
    outline: none;
    box-shadow: none;
    border: 1px solid #9208F3;
    box-shadow: 0 4px 8px 0 #9b28a816, 0 6px 10px 0 #9108f322;
}

.search-input input:focus {
    background: #2E2E2ECC;
    color: #E9DEFF;
    outline: none;
    box-shadow: none;
    border: 1px solid #9208F3;
    box-shadow: 0 4px 8px 0 #9b28a816, 0 6px 10px 0 #9108f322;
}

.search-input input::placeholder {
    color: #e9deffbb;
}

.search-input img{
    position: absolute;
    right: 25px;
    top: 10px;
    color: #E9DEFF;
}

.search-input-results{
    position: absolute;
    z-index: 99;
    top: 50px;
    left: 220px;
}

.input-result-card{
    padding: 15px 15px 0 15px;
    border-radius: 10px;
    border-left: 1px solid #DA0FF6;
    border-bottom: 1px solid #63076f;
    border-right: 1px solid #DA0FF6;
    border-top: 1px solid #db0ff687;
    background-color: #0000007b;
    cursor: pointer;
}
.input-result-card:hover{
    background-color: #2b292964;
}

.input-result-card p{
    color: #ffffffdb;
    font-size: 12px;
}

.search-result{
    width: 850px;
    margin: 1rem;
    padding: 15px;
    border-radius: 14px;
    background-color: #05000dc5;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.result-heder{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.result-heder h2{
    color: #ffffffe3;
    font-size: 16px;
}
.result-heder p{
    color: #ffffff75;
    font-size: 12px;
}


/* === Recommended Card === */
.recommended-card{
    background: #0000008A;
    border-radius: 21px;
    backdrop-filter: blur(20px);
    border: 1px solid #9208F3;
    padding: 15px;
    display: flex;
    align-items: start;
    /* z-index: -1; */
    position: relative;
    cursor: pointer;
}



.reco-card-image{
    width: 50px;
    border-radius: 10px;
}

.recommended-card:hover {
    background: #3535355b;
}

.scrollable-banner {
    overflow-x: auto;
    padding: 10px 0;
    cursor: grab;
    user-select: none;
    width: calc(100% + 10vw);
    margin-left: -5vw;
}
.scrollable-banner:active {
    cursor: grabbing;
}

.scrollable-banner .row {
    display: flex;
    flex-wrap: nowrap;
}

.scrollable-banner::-webkit-scrollbar {
    height: 8px;
}
.scrollable-banner2::-webkit-scrollbar {
    height: 8px;
}
.scrollable-banner3::-webkit-scrollbar {
    height: 8px;
}

/* .scrollable-banner::-webkit-scrollbar-thumb {
    background-color: #9208F3;
    border-radius: 4px;
} */


/* ------ Hover Effect ------ */

.hover-card {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transform-style: preserve-3d;
    transition: transform 0.1s ease-out;
}

    
.hover-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 240px;
    height: 240px;
    background: radial-gradient(circle, #cc08f36c, transparent 60%);
    border-radius: 50%;
    pointer-events: none; 
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease;
    opacity: 0;
}
    
.hover-card:hover::before {
    opacity: 0.3;
    animation: follow-cursor 0.02s infinite;
}
    
@keyframes follow-cursor {
    from { transform: translate(calc(var(--x) - 120px), calc(var(--y) - 120px)); }
    to { transform: translate(calc(var(--x) - 120px), calc(var(--y) - 120px)); }
}

/* ---------- */

.main-info p{
    font-size: 11px;
    font-weight: 100;
    color: #ffff;
    margin-right: -10px;
    margin-left: 15px;

    display: block;
    overflow: hidden;        
    text-overflow: ellipsis; 
}

.main-info p:first-child{
    font-size: 13px;
    white-space: nowrap; 
    width: 200px;   
}

.main-info img{
    position: absolute;
}

.marginNegative{
    padding-bottom: 0;
    width: 345px;
}

.zIndex999 {
    z-index: 999;
}

.dropdown-menu{
    border-radius: 12px !important;
    background-color: #1E1E1E;
    z-index: 1000 !important;
    height: 100px;
    border: 1px solid #9B28A8;

}

.dropdown-menu button{
    color: #ffff;
}
.dropdown-menu button:hover{
    opacity: 0.5;
}
.dropdown-menu img:first-child{
    margin-top: 5px;
}
.dropdown-menu img{
    margin-top: -5px;
}

.dropdown-menu hr{
    color: #9B28A8;
    font-size: large;
    margin: 13px;
} 

.dropdown-item {
    background-color: transparent;
    border: none;
    padding: 0;
    text-align: center;
}

.dropdown-item:hover,
.dropdown-item:active,
.dropdown-item:focus {
    background-color: transparent;
    color: #ffff;
}


/* === footer === */
.footer{
    background-color: #000000;
    border-top: 0.5px solid #313131;
}
.footer p{
    color: #77798F;
    font-family: "Poppins", "Arimo", serif;
    font-size: 14px;
    font-weight: 400;
}

/* ==== meeting Card ==== */
.meeting-card{
    backdrop-filter: blur(10px);
    background-color: #05000dbe;
    border-radius: 17px;
    padding: 30px;
    color:#ffff;
    height: fit-content;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.recoding{
    font-display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.recoding span{
    color: #ffff;
}

.chart-lead-card{
    position: relative;
    height: 180px;
    width: 250px;
    background-color: #3b143f1f;
    border-radius: 10px;
    border: 1px solid rgba(211, 211, 211, 0.307);
}

.edit-icons{
    position: absolute;
    width: 120px;
    height: 50px;
    right: -15px;
    top: 5px;
    display: flex;
    align-items: start;
    justify-content:center;
    gap: 5px;
}

.add-persona{
    position: relative;
    height: 180px;
    width: 250px;
    background-color: #9b28a841;
    border-radius: 10px;
    border: 1px solid rgba(211, 211, 211, 0.307);
}

.add-persona h5{
    color: #ffff;
    font-size: 16px;
}

.add-persona i{
    color: #ffff;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
}

.chart-lead-card{
    padding: 15px;
    height: fit-content;
}

.chat-card, .meeting{
    flex-direction: row;
    align-items: start;
}

.chat-card-img{
    box-shadow: 0 4px 8px #9208F3;
    border-radius: 10px;
    width: 25%;
}

.chart-lead-card h6{
    margin: 20px 0;
}
.chart-lead-card p{
    font-size: 10px;
    border-top: 2px dashed #9208F3;
    padding-top: 4px;
}

.line-arrow img{
    width: 70%;
    margin: 25px 0;
}

.chart-lead-card2{
    height: fit-content;
    width: 250px;
    background-color: #3b143f1f;
    border-radius: 10px;
    border: 1px solid rgba(211, 211, 211, 0.307);
    padding: 15px;
}

.chart-lead-card2 p{
    border-top: 2px dashed #9208F3;
}
.chart-lead-card2 span{
    color: #e9deff98;
}

/* transcript */
.transcript{
    height: 67vh;
    width: 310px;
    background-color: #3b143f1f;
    border-radius: 10px;
    border: 1px solid rgba(211, 211, 211, 0.307);
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-end;
}

.transcript-heading{
    background-color: #9208F3;
    text-align: center;
    border-radius: 10px 10px 0 0;
    padding: 5px;
    margin-bottom: auto;
}

.mic{
    margin-top: -50px;
    padding-bottom: 15px;
}

.mic img{
    width: 40px;
}


.meeting-input{
    position: relative;
}

.meeting-input input {
    background: #2E2E2ECC;
    height: 45px;
    color: #E9DEFF;
    outline: none;
    border: 0.1px solid #595858cc;
    margin-bottom: 15px;
}

.meeting-input input:focus {
    background: #2E2E2ECC;
    color: #e9deffb7;
    outline: none;
}

.meeting-input input::placeholder {
    color: #e9deff4e;
}

.input-icons{
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
}

.input-icons img:first-child{
    padding-right: 5px;
    border-right: 2px solid #e9deff67;
}

.input-icons img{
    margin-left: 5px;
}

.chart-message-user1{
    background-color: #9208F3;
    padding: 5px;
    border-radius: 5px 5px 0 5px;
    max-width: 75%;
    width: auto;
    margin-left: auto;
    height: auto;
}
.chart-message-user1 p{
    font-size: 11px;
    margin-right: 0px;
    margin-top: 5px;
}
.chart-message-user1 img{
    width: 40px;
}
.chart-message-user1 span{
    font-size: 10px;
    text-align: end;
}

.user2 span{
    font-size: 10px;
    text-align: start !important;
}

.user2{
    background-color: #2C2C2C;
    margin-right: 65px;
    border-radius: 5px 5px 5px 0;
    margin-left: auto;
    width: 75%;
}

.trans-body{
    overflow-y: scroll;
    max-height: 100%;
    height: 100%;
    flex-grow: 1;
    padding-bottom: 0.5rem;
}

/* Hide scrollbar for every browser */
.trans-body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.trans-body::-webkit-scrollbar {
    display: none;
}

/* === Modal Dialog === */
.custom-modal{
    width: 430px ;
    height: 420px ;
    border-radius: 10px;
    border: 1px solid rgba(211, 211, 211, 0.307);
    padding: 15px;
    background: #05000DCC;
}

.custom-modal h4{
    color: #E9DEFF;
    font-size: 14px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.custom-modal i{
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
}
.custom-modal button{
    background-color: #1E1E1E;
    border-radius: 10px;
    color: #959595;
    height: 40px;
    width: 250px;
    border: none;
    text-align: left;
    padding-left: 20px;
    font-size: 11px;
}

.custom-modal button:hover{
    background-color: #0e0e0e;
    color: #bcbaba;
}

.custom-modal button:active,
.custom-modal button:focus{
    background-color: #0e0e0e;
    color: #e7e7e7;
}

/* -------- */
.custom-tooltip .tooltip-inner {
    background-color: #9208F3;
    color: #E9DEFF; 
}

.custom-tooltip .tooltip-arrow::before {
    border-top-color: #9208F3 !important;
}

/* --- signUp Model --- */
.signUp-model{
    height: 400px ;
    color: #fff;
    text-align: center;
    border-top: 1px solid #9208F3;
    border-left: 1px solid #9208F3;
    border-bottom: 1px solid #220139;
    border-right: 1px solid #220139;
}
.signUp-model p{
    color: #e9deff84;
    font-size: 16px;
}
.signUp-model h3{
    font-size: 26px;
    margin-top: 2rem;
}


.sign-in-btn .first-button{
    border-radius: 5px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    background: linear-gradient(105.93deg, #9B28A8 10.92%, #9208F3 88.71%);
}

.sign-in-btn .second-button {
    border-radius: 5px;
    text-align: center;
    color: #ffff;
    font-size: 14px;
    border: 1px solid #9B28A8;
    background: linear-gradient(105.93deg, #9B28A8 10.92%, #9208F3 88.71%);

    background: transparent;
    border-image-slice: 1;
}


.sign-in-btn p{
    color: #9208F3;
    font-size: 12px;
}

.terms{
    margin-top: 30px;
    cursor: pointer;
}
.terms u{
    color: #ffff;
    margin-left: 5px;
}

.terms u, .terms p{
    font-size: 12px;
    margin-bottom: 0;
}

.privacy-model{
    border-radius: 20px;
    color: #fff;
    border-top: 1px solid #D368BC;
    border-left: 1px solid #D368BC;
    border-right: 1px solid #481e43b0;
    border-bottom: 1px solid #481e43b0;
    background-color: #00000066;
    backdrop-filter: blur(20px);
}

.privacy-model-width{
    max-width: 80%;
}

.privacy-model p{
    font-size: 16px;
}

.privacy-model ol li{
    font-size: 16px;
    color: #ffffffb7;
    line-height: 30px;
}

.privacy-model ol li span{
    font-size: 16px;
    color: #fff;
}

#backToFirstModal2, 
#backToFirstModal{
    color: #9B28A8;
    margin-top: 12vh;
    cursor: pointer;
}

.ShareModel{
    background-color: #05000ddc;
    color: #fff;
    border-radius: 17px;
    border: 1px solid #9208F3;
    height: 230px;
    width: 450px;
    margin: auto;
}

.ShareModel img{
    width: 50px;
    cursor: pointer;
}

.ShareModel p{
    margin-top: 5px;
    font-size: 13px;
}

/* ----- Persona Model ----- */
.persona-model{
    height: 100%;
    color: #ffff;
    text-align: center;
    border-top: 1px solid #9208F3;
    border-left: 1px solid #9208F3;
    border-bottom: 1px solid #220139;
    border-right: 1px solid #220139;
    padding-bottom: 3rem;
}

.persona-model button{
    font-size: 12px;
}

.custom-add-persona{
    margin-top: -15px;
}

.custom-add-persona input{
    padding-left: 20px;
    background-color: #1E1E1E;
    color: #E9DEFF;
    border-radius: 10px;
    border: none;
    height: 40px;
    width: 75%;
}

.custom-add-persona input:focus {
    background: #1E1E1E;
    color: #E9DEFF;
    outline: none;
}

.custom-add-persona input::placeholder {
    color: #959595;
    font-size: 14px;
}

.custom-add-persona button{
    border-radius: 5px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    background: linear-gradient(105.93deg, #9B28A8 10.92%, #9208F3 88.71%);
    box-shadow: 0 8px 16px 0 #9b28a830, 0 6px 20px 0 #9108f346;
    width: 40%;
    font-weight: 500;
    padding: 0;
}

/* ------ persona-model (Remove & Favorite) ------- */
.persona-model-popups{
    height: 100%;
    color: #ffff;
    text-align: center;
    border-top: 1px solid #9208F3;
    border-left: 1px solid #9208F3;
    border-bottom: 1px solid #220139;
    border-right: 1px solid #220139;
    padding-bottom: 3rem;
    background-color: #05000DCC;
}

.persona-model-popups img{
    width: 45%;
    margin: auto;
}

.persona-model-popups h2{
    text-align: center;
    font-size: 25px;
}
.persona-model-popups span{
    color: #981EBE;
}
.persona-model-popups button{
    width: 30%;
    padding: 0;
    box-shadow: 0 4px 8px 0 #9b28a816, 0 6px 10px 0 #9108f322;
}

.persona-model-popups .second-button{
    border-radius: 5px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    background: linear-gradient(105.93deg, #9B28A8 10.92%, #9208F3 88.71%);
}

.persona-model-popups .first-button {
    border-radius: 5px;
    text-align: center;
    color: #ffff;
    font-size: 14px;
    border: 1px solid #9B28A8;
    background: linear-gradient(105.93deg, #9B28A8 10.92%, #9208F3 88.71%);

    background: transparent;
    border-image-slice: 1;
}

/* ------ edit-persona ----- */
.edit-parsona{
    width: 100%;
    height: 100% !important;
    max-height: 100vh !important;
}
.edit-parsona h4{
    font-size: 16px;
    text-align: start;
    margin: 1rem 0 0 3rem;
}

.edit-persona-body{
    background-color: #ffffff0b;
    border-radius: 8px;
    margin: 1rem 2rem 0 2rem;
    padding: 20px 5px 10px 5px;
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none;
    padding-bottom: 5px;
}
.edit-persona-body::-webkit-scrollbar { 
    display: none;
}

.user-btn{
    height: 70px;
    margin-top: -1rem;
}
.user-btn img{
    width: 65px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
}
.user-btn button{
    border-radius: 8px;
    height: 30px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    background: linear-gradient(105.93deg, #9208F3 10.92%, #9B28A8 88.71%);
}

.edit-persona-body p{
    color: #959595;
    font-size: 10px;
    text-align: left;
    margin: 10px 0;
}

.edit-persona-body input{
    background-color: #1E1E1E;
    color: #E9DEFF;
    border-radius: 8px;
    font-size: 12px;
    border: none;
    width: 100%;
    height: 40px;
    padding-left: 20px;
    padding-right: 10px;
}

.edit-persona-body textarea{
    background-color: #1E1E1E;
    color: #E9DEFF;
    border-radius: 8px;
    font-size: 12px;
    border: none;
    width: 100%;
    height: 100px;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 10px;
    resize: none;
    overflow-y: hidden;
}

.edit-persona-body input:focus,
.edit-persona-body textarea:focus {
    background: #1E1E1E;
    color: #E9DEFF;
    outline: none;
}

.edit-persona-body input::placeholder,
.edit-persona-body textarea::placeholder {
    color: #959595;
    font-size: 12px;
}

.edit-persona-btn{
    width: 60%;
    text-align: center;
    margin-top: 10px;
}
.edit-persona-btn button{
    padding: 0;
}

.edit-persona-btn .first-button{
    border-radius: 5px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    background: linear-gradient(105.93deg, #9B28A8 10.92%, #9208F3 88.71%);
}

.edit-persona-btn .second-button {
    border-radius: 5px;
    text-align: center;
    color: #ffff;
    font-size: 14px;
    border: 1px solid #9B28A8;
    background: linear-gradient(105.93deg, #9B28A8 10.92%, #9208F3 88.71%);

    background: transparent;
    border-image-slice: 1;
}

/* ------Accordion---------- */
.accordion-item{
    background: transparent !important;
    border: none;
    margin: 0;
    padding: 0;
    color: #E9DEFF;
}

.accordion-item button,
.accordion-item .accordion-button,
.accordion-item .accordion-button:active,
.accordion-item .accordion-button:focus,
.accordion-item .accordion-button:hover,
.accordion-item button:active,
.accordion-item button:focus,
.accordion-item button:hover{
    background: #1E1E1E;
    color: #a2a2a2;
    outline: none;
    box-shadow: none;
    width: 100%;
    font-weight: 100;
}

.accordion-button::after {
    color: #959595;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M2 5L8 11L14 5'/%3E%3C/svg%3E");
}

.accordion-button:not(.collapsed)::after {
    color: #959595;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M2 5L8 11L14 5'/%3E%3C/svg%3E");
}

.accordion-body{
    width: 100%;
    margin-top: 5px;
    padding: 0;
}
.accordion-body button{
    margin-top: 3px;
}




/* Customize the range slider container */
.Range-item{
    background: #1E1E1E;
    color: #a2a2a2;
    outline: none;
    box-shadow: none;
    width: 100%;
    border-radius: 8px;
    padding-bottom: 10px;
    margin-bottom: 5px;
}

.range-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.Range-item p {
    font-size: 14px;
    padding: 5px 10px;
    margin: 0;
}

.range-container label {
    color: #f9f9f9;
    font-size: 14px;
    margin: 0 10px;
}

/* Container for slider and value */
.range-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Slider customization */
.range-slider {
    flex-grow: 1;
    height: 3px; /* Thinner line */
    background: none; /* Remove background */
    color: #fff;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    border: none; /* Remove border */
    box-shadow: none; /* Remove shadow */
}

/* Customizing the track */
.range-slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px; /* Thinner track */
    cursor: pointer;
    background: #ffffff; /* Ensure the track is visible */
    border-radius: 3px;
}

.range-slider::-moz-range-track {
    width: 100%;
    height: 3px; /* Thinner track */
    cursor: pointer;
    background: #ffffff; /* Ensure the track is visible */
    border-radius: 3px;
}

/* Customizing the thumb */
.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px; 
    height: 15px; 
    background: #9B28A8; 
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    margin-top: -6px;
}

.range-slider::-moz-range-thumb {
    width: 15px; 
    height: 15px; 
    background: #9B28A8; 
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    margin-top: -6px;
}


/* Range value that follows thumb */
.range-value {
    position: absolute;
    top: 35px;
    padding-left: 28px;
    transform: translateX(-50%);
    color: #ffffff;
    font-size: 14px;
    pointer-events: none;
    white-space: nowrap;
}

/* ----------Active tab------------- */
/* Fading effect */
.fade-out {
    opacity: 0.2;
    transition: opacity 0.3s ease;
    backdrop-filter: blur(20px);
}

.display-none{
    display: none;
}
.mRight{
    margin-right: 15px;
}
.resize-maincard{
    width:fit-content;
}

.cross-button i{
    font-size: large;
    position: absolute;
    z-index: 9999;
    right: 30vh;
    top: 12vh;
    margin: 2rem;
    color: #ffff;
    cursor: pointer;
}

.neon-effect{
    border: 1px solid #9208F3;
    box-shadow: 0 4px 8px 0 #9b28a816, 0 6px 10px 0 #9108f322;
}

.direction-arrow{
    cursor: pointer;
}

.modal-content i,
.logo,
.mic img,
.input-icons img{
    cursor: pointer;
}

/* Z index of All recommended-card */

.Customzindex-1,
.Customzindex-2,
.Customzindex-3,
.Customzindex-4{
    z-index: 12;
}

.Customzindex-5,
.Customzindex-6,
.Customzindex-7,
.Customzindex-8{
    z-index: 8;
}

.Customzindex-9,
.Customzindex-10,
.Customzindex-11,
.Customzindex-12{
    z-index: 4;
}
/* ===================== */

.profile-div{
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.profile-div img{
    width: 35px;
    border-radius: 50%;
    overflow: hidden;
}
.profile-div p{
    font-size: 11px;
    margin-left: 3px;
    margin-top: auto;
    margin-bottom: auto;
}

.margin-minus{
    margin-top: -3rem;
}


.scrollable-banner3 {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    padding: 10px;
    margin-bottom: -3rem;
}

.scrollable-banner3 .row {
    display: flex;
    flex-wrap: nowrap;
}

.scrollable-banner2 {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    padding: 10px;
}

.scrollable-banner2 .row {
    display: flex;
    flex-wrap: nowrap;
}

.category-tab-btn {
    display: inline-block;
    flex: 0 0 auto;
    padding: 10px;
    margin: 0 5px;
    background-color: #9b28ba97;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    width: fit-content;
    height: 45px;
    color: #ccc;
}

.category-tab-btn:hover{
    background-color: #9a28ba;
    color: #ffff;
}

.category-tab-btn p{
    font-weight: 300;
}

.active-btn{
    background-color: #ccc;
    color: #9a28ba;
}
.cursor-block{
    cursor: no-drop;
}


/* ----- Choose Topic Modal---- */

:where(.css-dev-only-do-not-override-17seli4).ant-modal .ant-modal-content {
    padding: 0;
}

.TopicModel{
    background: linear-gradient(105.93deg, #6b0876 10.92%, #8f037a 88.71%);
    color: #ffff;
    margin: 0;
    padding: 15px;
    position: relative;
}

.search-input2,
.search-input2:focus,
.search-input2:active{
    width: 100%;
    padding: 10px 20px;
    outline: none;
    border-radius: 5px;
    border: none;
}

.skip-btn{
    border: none;
    background-color: #0E0026;
    padding: 10px 0;
    border-radius: 5px;
    color: #ffff;
    z-index: 9;
}

.modal-content {
    max-height: 80vh;
    overflow-y: auto; 
}

.Topics-ul-li ul li:hover{
    margin-left: 10px;
    padding: 5px 0;
    cursor: pointer;
    font-weight: bolder;
    color: #000000;
}

.modal-input-2{
    background: linear-gradient(105.93deg, #6b0876 10.92%, #8f037a 88.71%);
    color: #ffff;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.textarea-css:active,
.textarea-css:focus,
.textarea-css{
    height: 100px;
    width: 200px;
    border-radius: 5px;
    outline: none;
    resize: none;
    padding: 5px;
}

.textarea-css::-webkit-scrollbar{
    width: 3px;
}

.selected-btn{
    color: #ffff;
    background-color: #000000d2;
}