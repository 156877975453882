@media screen and (max-width: 1529px){
    /* Landing Page */
    .logo img{
        width: 100px;
    }
    .linear-btn {
        font-size: 11px;
        border-radius: 18px;
    }
    .linear-btn span {
        padding: 5px 8px;
        border-radius: 18px;
    }
    .header-vector{
        top: 0;
        left: 20vw;
        z-index: 0 !important;
    }
    .header-vector img{
        width: 60vw;
        z-index: 0 !important;
    }
    .main-text{
        font-size: 30px;
        font-weight: 300;
        margin: 15px 0;
    }
    .left-card{
        width: 360px;
        height: 200px;
    }
    .arrow-line img{
        width: 0%;
    }
    .middle-card{
        height: 200px;
        margin-bottom: 1rem;
    }

    .direction-arrow img{
        width: 100px;
    }

    .search-input{
        margin-bottom: 13px;
    }

    .search-input-results{
        left: 0;
    }

    .search-result{
        width: 867px;
        margin-left: 15vw;
    }

    .marginNegative{
        padding-bottom: 0;
        width: 300px;
    }

    .scrollable-banner {
        margin-left: 0px;
        width: 96%;
    }

    .privacy-model-width{
        max-width: 90%;
    }

    .privacy-model h3, 
    .privacy-model p{
        text-align: center;
    }

    .privacy-model p,
    .privacy-model ol li,
    .privacy-model ol li span{
        font-size: 14px;
    }

    #backToFirstModal2, 
    #backToFirstModal{
        margin-top: 5vh;
    }
    .main-info p {
        margin-right: -18px;
        width: 83%;
    }
        
    .main-info p:first-child{
        width: 180px;  
    }

    .line-arrow img{
        width: 60%;
        margin: 5px 0;
    }
    .user-btn img {
        width: 55px;
        height: 55px;
    }
    .chart-lead-card2{
        padding: 10px 10px 0 10px;
    }
    .add-persona {
        height: 160px;
    }
    .chart-lead-card {
        padding: 15px 15px 0 15px;
    }

    .chart-message-user1{
        max-width: 75%;
    }
    .chart-message-user1 img{
        width: 40px;
    }
    .user2{
        margin-right: 70px;
    }
}

@media screen and (max-width: 1400px){
    .search-result{
        width: 720px;
    }
}

@media screen and (max-width: 1200px){
    .main-info p{
        font-size: 11px;
        font-weight: 100;
        color: #ffff;
        margin-right: -10px;
        margin-left: 15px;
        width : 90%;
        display: block;
        overflow: hidden;        
        text-overflow: ellipsis; 
    }
    
    .main-info p:first-child{
        font-size: 13px;
        white-space: nowrap; 
    }

    .left-card{
        width: 550px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
    }
    .middle-card{
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }
    .right-card{
        width: auto;
        margin-left: auto;
        margin-right: auto;
    } 

    .search-input-results{
        left: 0;
    }

    .search-result{
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
    }

    /* Meeting Page  */
    .meeting{
        flex-direction: column;
        align-items: center;
        height: 220vh;
    }
    .meeting-card{
        height: 180vh;
    }
    .transcript{
        height: 75vh;
        width: 70vw;
        margin-bottom: 10vh;
    }

    .chart-message-user1{
        max-width: 70%;
    }
    .chart-message-user1 img{
        width: 50px;
    }
    .user2{
        margin-left: 5px;
        margin-right: auto;
    }

    /* z-index */
    .Customzindex-1,
    .Customzindex-2,
    .Customzindex-3{
        z-index: 12;
    }

    .Customzindex-4,
    .Customzindex-5,
    .Customzindex-6{
        z-index: 9;
    }

    .Customzindex-7,
    .Customzindex-8,
    .Customzindex-9{
        z-index: 6;
    }

    .Customzindex-10,
    .Customzindex-11,
    .Customzindex-12{
        z-index: 3;
    }
}


@media screen and (max-width: 992px){
    .search-result {
        width: 715px;
    }

    /* z-index */
    .Customzindex-1,
    .Customzindex-2{
        z-index: 12;
    }
    .Customzindex-3,
    .Customzindex-4{
        z-index: 10;
    }
    .Customzindex-5,
    .Customzindex-6{
        z-index: 8;
    }
    .Customzindex-7,
    .Customzindex-8{
        z-index: 6;
    }
    .Customzindex-9,
    .Customzindex-10{
        z-index: 4;
    }
    .Customzindex-11,
    .Customzindex-12{
        z-index: 2;
    }
}

@media screen and (max-width: 770px){
    .search-result {
        width: 540px;
    }
}

@media screen and (max-width: 576px){
    /* Landing Page */
    .logo img{
        width: 80px;
        margin-right: 20px;
    }
    .linear-btn {
        font-size: 8px;
        border-radius: 20px;
    }
    .linear-btn span {
        padding: 5px 8px;
        border-radius: 20px;
    }
    .header-vector{
        top: 0;
        left: 0;
        opacity: 0.4;
    }
    .header-vector img{
        width: 0px;
        z-index: 0;
    }
    .main-text{
        font-size: 20px;
        font-weight: 200;
        margin: 8px 0;
    }

    .left-card{
        width: auto;
    }
    .arrow-line{
        margin-left: 0px;
        margin-right: -10px;
        margin-left: 10px;
        margin-bottom: 0px;
    }
    .arrow-line img{
        width: 0%;
    }
    .middle-card{
        height: 220px;
        width: auto;
    }

    .right-card{
        margin-left: 0px;
        margin-top: 30px;
    }
    .direction-arrow img{
        width: 100px;
    }

    .search-input{
        margin-bottom: 15px;
    }

    .marginNegative{
        width: 87vw;
    }

    .scrollable-banner {
        margin-left: 0px;
        width: 96%;
    }

    .privacy-model-width{
        max-width: 100%;
    }

    .privacy-model h3, 
    .privacy-model p{
        text-align: center;
    }

    .privacy-model p,
    .privacy-model ol li,
    .privacy-model ol li span{
        font-size: 14px;
    }

    #backToFirstModal2, 
    #backToFirstModal{
        margin-top: 5vh;
    }

    /* Meeting Page */
    .meeting-card{
        background: transparent;
        border: none;
    }

    .main-card{
        background: transparent;
        border: none;
    }

    .transcript{
        height: 75vh;
        margin-bottom: 3vh;
    }

    .chat-card, .meeting{
        flex-direction: column;
       align-items: center;
    }

    .chart-message-user1{
        width: auto;
        height: auto;
    }
    .user2{
        margin-left: 5px;
        margin-right: auto;
    }

    .mic{
        margin-top: 15px;
    }


    .edit-parsona{
        height: 90vh;
    }
    .edit-persona-body{
        margin: 1rem;
        padding: 10px;
    }

    .search-input-results{
        left: -10px;
    }
    .input-result-card{
        padding: 10px 10px 0 10px;
    }
    .search-result{
        width: 92vw;
        border-radius: 12px;
        margin-left: 6vw;
    }
    .result-heder h2{
        font-size: 14px;
    }
    .result-heder p{
        font-size: 10px;
    }
    .mRight{
        margin-right: 0px;
    }
    .cross-button i{
        right: 0;
        top: 27px;
        padding: 10px;
    }
    .resize-maincard {
        width: inherit;
    }

    .custom-add-persona input::placeholder {
        font-size: 10px;
    }
    .custom-add-persona input{
        padding-left: 10px;
        font-size: 12px;
    }

    .profile-div p{
        display: none;
    }

    .chart-lead-card2{
        margin: auto;
    }
    .line-arrow img{
        margin: 25px 10%;
    }

    /* z-index */
    .Customzindex-1{
        z-index: 12;
    }
    .Customzindex-2{
        z-index: 11;
    }
    .Customzindex-3{
        z-index: 10;
    }
    .Customzindex-4{
        z-index: 9;
    }
    .Customzindex-5{
        z-index: 8;
    }
    .Customzindex-6{
        z-index: 7;
    }
    .Customzindex-7{
        z-index: 6;
    }
    .Customzindex-8{
        z-index: 5;
    }
    .Customzindex-9{
        z-index: 4;
    }
    .Customzindex-10{
        z-index: 3;
    }
    .Customzindex-11{
        z-index: 2;
    }
    .Customzindex-12{
        z-index: 1;
    }
}
